import React from 'react';

const Protected_Page = () => {
  
    return (
        <>
            <h1>Welcome to the protected page!</h1>
        </>
    )
}

export default Protected_Page